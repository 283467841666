var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"toggle-btn-wrapper"},[_c('div',{staticClass:"toggle-btn-label",domProps:{"innerHTML":_vm._s(_vm.toggleLabelHTML)}}),_vm._v(" "),_c('div',{staticClass:"toggle-btn-group"},[_c('button',{class:{
                'toggle-btn': true,
                left: true,
                active: _vm.activeSelected === _vm.toggleLeftLabel,
            },on:{"click":_vm.togglePlanType}},[_c('span',[_vm._v("\n                "+_vm._s(_vm.toggleLeftLabel)+"\n            ")])]),_vm._v(" "),_c('button',{class:{
                'toggle-btn': true,
                right: true,
                active: _vm.activeSelected === _vm.toggleRightLabel,
            },on:{"click":_vm.togglePlanType}},[_c('span',[_vm._v("\n                "+_vm._s(_vm.toggleRightLabel)+"\n            ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }