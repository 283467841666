import PageAttributes from 'aa/vue/models/PageAttributes';
import PageAttributeService from 'aa/vue/services/PageAttributeService';

export default {
    namespaced: true,

    state: new PageAttributes(),

    mutations: {
        setPageAttr(state, pageAttrs) {
            let newAttrs = new PageAttributes();

            for (let key in pageAttrs) {
                if (pageAttrs.hasOwnProperty(key)) {
                    newAttrs.attributes[key] = pageAttrs[key];
                }
            }

            state.attributes = newAttrs.attributes;
        },
    },

    actions: {
        loadPageAttributes(context, payload) {
            let tags = [];
            if (payload.flowType) {
                tags.push(payload.flowType);
            }
            if (payload.name) {
                tags.push(payload.name);

                if (
                    payload.flowType &&
                    payload.flowType.indexOf('campaign_') === 0 &&
                    payload.name.indexOf('explainer') > -1
                ) {
                    tags.push('explainer_set_generic_campaign');
                }
            }

            return context.dispatch('loadByTags', tags);
        },

        loadByTags(context, payload) {
            return new Promise((resolve, reject) => {
                PageAttributeService.get(
                    payload,
                    context.rootState.user.statusCode,
                    this.$http,
                ).then((pageAttrs) => {
                    context.commit('setPageAttr', pageAttrs);

                    resolve();
                });
            });
        },

        loadSkipStepNotification(context, payload) {
            return new Promise((resolve, reject) => {
                PageAttributeService.getSkipStepNotification(payload.partnerCode, this.$http)
                    .then((response) => {
                        const pageAttributes = context.state.attributes;
                        pageAttributes['notificationTitle'] = response.result.notificationTitle;
                        pageAttributes['notificationMessage'] = response.result.notificationMessage;
                        resolve(response.result);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        setPageAttributes(context, payload) {
            context.commit('setPageAttr', payload);
        },

        mapValues({ state, commit }, map) {
            if (!map || typeof map !== 'object') return;

            Object.keys(map).forEach((key) => {
                if (state.attributes[key]) state.attributes[key] = map[key];
            });

            commit('setPageAttr', Object.assign({}, state.attributes));
        },
    },

    getters: {
        hideBundlePlanCTAs: (state) => {
            return state.attributes.hide_bundle_plan_ctas;
        },

        hideBasePlanCTAs: (state) => {
            return state.attributes.hide_base_plan_ctas;
        },

        flowTag: (state, getters, rootState, rootGetters) => {
            let flowType = rootGetters['flow/type'];

            if (typeof flowType === 'string') {
                let idx = flowType.indexOf('f-');

                if (idx === 0) {
                    return flowType.length > 2 ? flowType.substr(2) : null;
                } else {
                    return flowType;
                }
            }

            return null;
        },

        getAllPageAttributes: (state) => {
            return state.attributes;
        },
    },
};
