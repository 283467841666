// START feature/PLOCTOPLUS-2043-intl-center-align-upsell-hero
import { AbTest } from 'abTests/lib/AbTest';
import Breakpoints from 'helpers/breakpoints';

const EXP_NAME = 'intl_web_center_content_in_hero_upsell_bucket';
const CENTER_ALIGN_THREE_CTAS = 'center_align_three_ctas';
const CENTER_ALIGN_ONE_CTA = 'center_align_one_cta_sign_up';
const HERO_BG_IMG_URL =
    'https://wwwimage-intl.pplusstatic.com/thumbnails/photos/w1920-q80/cbs_page_attribute/abtest-upsell_gen_hero1_weblandscape_2560x960_090823.jpg';

export class IntlUpsellCenterAlignHeroes extends AbTest {
    onRun() {
        new IntlUpsellCenterAlignHeroesHandler(this.variant);
    }
}

class IntlUpsellCenterAlignHeroesHandler {
    constructor(userVariant) {
        this.variant = userVariant ?? '';
        this.breakpoints = new Breakpoints();
        this.currentBreakpoint = this.breakpoints.getCurrentBreakpointName();
        this.init();
    }

    hasVariant() {
        return this.variant === CENTER_ALIGN_THREE_CTAS || this.variant === CENTER_ALIGN_ONE_CTA;
    }

    init() {
        this.setupBackgroundEvents();
    }

    addHeroBackgroundImage() {
        this.currentBreakpoint = this.breakpoints.getCurrentBreakpointName();
        // console.log('this.currentBreakpoint', this.currentBreakpoint)
        if (this.isMobileOrTP()) {
            // console.log('no test - OR - test setting MP/ML/TP to', this.heroBackgroundMobile)
            this.__setBackgroundImage(this.heroBackgroundMobile);
        } else if (this.showDesktopImage()) {
            // console.log('no test - OR - test setting TL to', this.heroBackgroundDesktop)
            this.__setBackgroundImage(this.heroBackgroundDesktop);
        } else {
            // console.log('test setting D or DW to', HERO_BG_IMG_URL);
            // show variant background image
            this.__setBackgroundImage(HERO_BG_IMG_URL);
        }
    }

    isMobileOrTP() {
        return (
            this.currentBreakpoint === 'mobilePortrait' ||
            this.currentBreakpoint === 'mobileLandscape' ||
            this.currentBreakpoint === 'tabletPortrait'
        );
    }

    showDesktopImage() {
        return (
            !this.hasVariant() ||
            (this.hasVariant() && this.currentBreakpoint === 'tabletLandscape')
        );
    }
}

// temp prototypes to be overwritten by setupPrototypesForIntlCenterAlignHeroAbTest below, called from AAUpsellHeroVideo.js
IntlUpsellCenterAlignHeroesHandler.prototype.variants = {};
IntlUpsellCenterAlignHeroesHandler.prototype.hero = null;
IntlUpsellCenterAlignHeroesHandler.prototype.heroBackgroundDesktop = '';
IntlUpsellCenterAlignHeroesHandler.prototype.heroBackgroundMobile = '';
IntlUpsellCenterAlignHeroesHandler.prototype.setupBackgroundEvents = function () {};
IntlUpsellCenterAlignHeroesHandler.prototype.__setBackgroundImage = function () {};

/**
 *
 * @returns {boolean}
 */
export function intlCenterAlignHeroAbTest() {
    return typeof CBS?.AbTestsConfigs?.modules?.IntlUpsellCenterAlignHeroes !== 'undefined';
}

/**
 *
 * @returns {(function(): void)|*}
 */
export function setupPrototypesForIntlCenterAlignHeroAbTest() {
    return function () {
        IntlUpsellCenterAlignHeroesHandler.prototype.hero = this.hero;
        IntlUpsellCenterAlignHeroesHandler.prototype.heroBackgroundDesktop =
            this.heroBackgroundDesktop;
        IntlUpsellCenterAlignHeroesHandler.prototype.heroBackgroundMobile =
            this.heroBackgroundMobile;
        IntlUpsellCenterAlignHeroesHandler.prototype.setupBackgroundEvents =
            this.setupBackgroundEvents;
        IntlUpsellCenterAlignHeroesHandler.prototype.__setBackgroundImage =
            this.__setBackgroundImage;
    };
}

// END feature/PLOCTOPLUS-2043-intl-center-align-upsell-hero
