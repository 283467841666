<template>
    <div :class="getClassList">
        <div class="grid-item-interior">
            <div v-if="showBestValue" class="best-value">
                <span>{{ getBestPillCopy }}</span>
            </div>
            <div class="plan-name">{{ plan.planTitle }}</div>
            <div v-if="hasCoupon" class="plan-discount-percentage">
                {{ getCouponString }}
            </div>
        </div>
    </div>
</template>
<script>
    import PickAPlanBox from 'aa/vue/components/PickAPlanBox';
    import { mapGetters } from 'vuex';

    export default {
        name: 'UpsellPlansHeaderItem',
        extends: PickAPlanBox,
        data: function () {
            return {};
        },
        props: {
            plan: {
                type: Object,
                required: true,
            },
        },
        computed: {
            ...mapGetters({
                upsellPlansAnnualEnabled: 'featureFlags/upsellPlansAnnualEnabled',
            }),
            hasCoupon() {
                if (this.plan.hasValidCoupon()) {
                    this.$emit('hasCoupon');
                }
                return this.plan.hasValidCoupon();
            },
            getCouponString() {
                return this.plan?.couponString || '';
            },
            showBestValue() {
                return this.plan.isCF;
            },
            getBestPillCopy() {
                return this.$getLocale('best_value');
            },
            getClassList() {
                return {
                    'grid-item': true,
                    hasAnnual: this.upsellPlansAnnualEnabled,
                };
            },
        },
    };
</script>
