import Duration from 'aa/vue/models/Duration';

export default class CouponDiscount {
    constructor(config = {}) {
        this.code = config.recurlyCoupon.code;
        // e.g. 'USD'
        this.currencyCode = config.recurlyPrice.currency.code;

        // e.g. 'free_trial', 'percent', 'fixed_amount || dollars'
        this.discountType = config.recurlyCoupon?.discount?.type;

        // e.g. '7.89'
        this.priceSubtotal = config.recurlyPrice.next.subtotal;
        // e.g. '1.23', '0.00'
        this.priceDiscount = this.isPriceDiscount ? config.recurlyPrice.next.discount : null;

        this.duration = new Duration(null, null);

        this.__setDuration(config.recurlyCoupon);
    }

    get isFreeTrial() {
        return this.discountType === 'free_trial';
    }

    get isPriceDiscount() {
        return this.discountType && !this.isFreeTrial;
    }

    __setDuration(recurlyCoupon) {
        if (!recurlyCoupon) {
            return;
        }

        let amount, unit;

        if (this.isFreeTrial) {
            amount = recurlyCoupon.discount.trial.amount;
            unit = recurlyCoupon.discount.trial.unit;
        } else if (this.isPriceDiscount) {
            amount = recurlyCoupon.temporal_amount;
            unit = recurlyCoupon.temporal_unit;
        }

        if (typeof amount === 'number' && unit && String(unit).match(/[a-z]+/)) {
            this.duration = new Duration(amount, unit, true);
        }
    }
}
