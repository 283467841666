import { ValueProp } from 'aa/vue/models/upsell/ValueProp';

export default class PageAttributes {
    constructor() {
        // all page attributes model
        this.attributes = PageAttributes.allAttributes();
    }

    /**
     * @returns {Object}
     */
    static allAttributes() {
        return Object.assign(
            PageAttributes.sharedAttributes(),
            PageAttributes.pickPlanAttributes(),
            PageAttributes.paymentAttributes(),
            PageAttributes.forgotPassAttributes(),
            PageAttributes.rendezvousAttributes(),
            PageAttributes.signInAttributes(),
            PageAttributes.notificationAttributes(), // CBSCOMSUB-4696
            PageAttributes.giftAttributes(),
            PageAttributes.eduAttributes(),
            PageAttributes.tveRendezvousAttributes(),
            PageAttributes.oauthAttributes(),
            PageAttributes.resellerAttributes(),
            PageAttributes.upsellAttributes(),
        );
    }

    static sharedAttributes() {
        return {
            aa_page_header: '',
            aa_page_header_exsub: '',
            aa_page_sub_header: '',
            aa_page_sub_header_exsub: '',
            aa_steps_header: '',
            aa_steps_counter: '',
            aa_marquee_heading: '',
            aa_marquee_body: '',
            cta_form_submit: '',
        };
    }

    static pickPlanAttributes() {
        return {
            aa_pap_sub_heading: '',
            aa_pap_sub_heading_ex_sub: '',
            aa_pap_gift_sub_heading_format: '',
            aa_pap_plan_heading_cf: '',
            aa_pap_plan_heading_lcp: '',
            aa_pap_trial_copy: '',
            aa_pap_coupon_prefix: '',
            aa_pap_plan_price_format_with_percent_coupon: '',
            aa_pap_plan_price_format_with_trial: '',
            aa_pap_annual_plan_monthly_price_format: '',
            // START TEST PLOCTOPLUS-2572: A/B Test: Annual Upsell on Payment Page - Revised UI/UX
            aa_payment_upgrage_to_annual_sub_title: '',
            aa_payment_upgrage_to_annual_title: '',
            aa_payment_upgrade_annual_monthly_price_format: '',
            aa_payment_upgrade_annual_pill_text: '',
            // END TEST PLOCTOPLUS-2572: A/B Test: Annual Upsell on Payment Page - Revised UI/UX
            aa_pap_lcp_feature_list_item_1: '',
            aa_pap_lcp_feature_list_item_2: '',
            aa_pap_lcp_feature_list_item_3: '',
            aa_pap_lcp_feature_list_item_4: '',
            aa_pap_lcp_feature_list_item_5: '',
            aa_pap_lcp_feature_list_item_6: '',
            aa_pap_lcp_feature_list_item_7: '',
            aa_pap_lcp_feature_list_item_8: '',
            aa_pap_lcp_feature_list_item_9: '',
            aa_pap_lcp_feature_list_item_10: '',
            aa_pap_cf_banner: '',
            aa_pap_cf_disclaimer: '',
            aa_pap_cf_feature_list_item_1: '',
            aa_pap_cf_feature_list_item_2: '',
            aa_pap_cf_feature_list_item_3: '',
            aa_pap_cf_feature_list_item_4: '',
            aa_pap_cf_feature_list_item_5: '',
            aa_pap_cf_feature_list_item_6: '',
            aa_pap_cf_feature_list_item_7: '',
            aa_pap_cf_feature_list_item_8: '',
            aa_pap_cf_feature_list_item_9: '',
            aa_pap_cf_feature_list_item_10: '',
            aa_pap_annual_plan_checkbox_copy: '',
            aa_pap_annual_plan_checkbox_format: '',
            aa_pap_button_text: '',
            aa_pap_disclaimer: '',
            aa_pap_marquee_body: '',
            aa_pap_marquee_heading: '',
            aa_pap_plan_heading_lc: '',
            aa_pap_price_copy_suffix: '',
            aa_pap_steps_header: '',
            aa_pap_annual_plan_coupon_checkbox_text: '',
            aa_pap_bundle_option_heading: '',
            aa_pap_bundle_option_description: '',
            aa_pap_bundle_option_cta: '',
            aa_pap_plans_order_ascending: false,
        };
    }

    static paymentAttributes() {
        return {
            aa_payment_paypal_instruction_copy: '',
            aa_payment_paypal_success_copy: '',
            aa_order_summary_title: '',
            aa_order_summary_change_plan_cta: '',
            aa_order_plan_label_lcp: '',
            aa_order_plan_label_cf: '',
            aa_order_summary_trial_label: '',
            aa_order_summary_discount_label: '',
            aa_order_summary_total_label: '',
            aa_order_summary_trial_end_copy: '',
            aa_order_summary_tax_disclaimer: '',
            aa_order_summary_coupon_disclaimer: '',
            aa_coupon_field_prompt: '',
            aa_coupon_field_cta: '',
            aa_payment_agreement_copy: '',
            aa_payment_agreement_copy_monthly: '',
            aa_payment_agreement_copy_annual: '',
            aa_payment_subtotal_class: '',
            aa_payment_paypal_success_copy_showtime: '',
            aa_payment_agreement_copy_showtime: '',
            aa_payment_agreement_copy_monthly_showtime: '',
            aa_payment_agreement_copy_annual_showtime: '',
            cta_form_submit_showtime: '',
            aa_campaign_upsell_disclaimer: '',
            hide_annual_upsell_on_payment: null,
        };
    }

    static forgotPassAttributes() {
        return {
            heading: '',
            sub_heading: '',
            success_heading: '',
            success_message: '',
            redirect_message: '',
            redirect_message_logged_in: '',
            error_message_fallback: '',
        };
    }

    static rendezvousAttributes() {
        return {
            faq_text_with_link: '',
            generic_error: '',
            completion_message: '',
            assistive_text: '',
            aa_rendezvous_image: '',
        };
    }

    static signInAttributes() {
        return {
            aa_sign_in_forgot_pass_link: '',
            aa_sign_in_fb_button: '',
            aa_sign_in_livetv_button: '',
        };
    }

    // CBSCOMSUB-4696
    static notificationAttributes() {
        return {
            notification_html: '',
        };
    }

    static giftAttributes() {
        return {
            aa_gift_faq_question_1: '',
            aa_gift_faq_question_2: '',
            aa_gift_faq_url_1: '',
            aa_gift_faq_url_2: '',
            aa_gift_faq_destination_1: '',
            aa_gift_faq_destination_2: '',
            aa_gift_third_party_error_title: '',
            aa_gift_third_party_error_description: '',
            error_message_gift_purchase: null,
        };
    }

    static eduAttributes() {
        return {
            field_label_first_name: '',
            field_label_last_name: '',
            field_label_email: '',
            field_label_birthdate: '',
            field_label_university: '',
            non_student_sign_up_text_with_link: '',
            sheerid_document_upload_prompt: '',
            btn_text_loading: null,
        };
    }

    static tveRendezvousAttributes() {
        return {
            tve_rendezvous_code_heading: '',
            tve_rendezvous_code_label: '',
            tve_rendezvous_code_error_message: '',
            tve_rendezvous_code_cta_text: '',
            tve_rendezvous_code_faq_text: '',
            tve_rendezvous_provider_heading: '',
            tve_rendezvous_provider_dropdown_default: '',
            tve_rendezvous_confirmation_heading: '',
            tve_rendezvous_confirmation_text: '',
        };
    }

    static oauthAttributes() {
        return {
            aa_oauth_previous_page_text_with_link: '',
            aa_oauth_sign_up_upsell_heading: '',
            aa_oauth_sign_up_upsell_message_with_link: '',
            aa_sign_in_sub_heading: null,
            oauth_client_logo: null,
            oauth_client_logo_alt: null,
            oauth_client_brand_message: null,
        };
    }

    static resellerAttributes() {
        return {
            aa_sign_up_sub_heading: '',
            cta_title: '',
            cta_image: '',
        };
    }

    /**
     *
     * @returns {{upsell_value_prop: ValueProp[]}}
     */
    static upsellAttributes() {
        return {
            upsell_value_prop: null,
        };
    }

    static get SHOWTIME_SUFFIX() {
        return '_showtime';
    }
}
