<template>
    <div id="pick-a-plan" :class="getPickAPlanWrapperClassList">
        <section class="padded-container">
            <div class="pick-a-plan-grid" aa-region="pick a plan">
                <div :class="getGridClass">
                    <steps-header v-if="!hasSkipExplainers" class="qt-PAPheader" />
                    <h1
                        v-else-if="dspHeading"
                        class="view-heading"
                        :tabindex="pageHeadingTabIndex"
                        ref="pageHeading"
                        @blur="removeHeadingTabIndex">
                        {{ getHeading }}
                    </h1>

                    <div :class="subHeadingClass">
                        {{ subHeadingCopy }}
                    </div>

                    <div v-if="displayAnnualCheckbox" class="annual-plan-toggle-wrapper">
                        <FormToggleButton
                            :toggleLabelHTML="annualCheckboxLabelCopy"
                            :defaultActive="annualToggleDefaultState"
                            :toggleLeftLabel="getToggleLeftLabel"
                            :toggleRightLabel="getToggleRightLabel"
                            @change="handleCheckboxChange"></FormToggleButton>
                    </div>

                    <div :class="plansGridClass">
                        <!-- START TEST PLOCTOPLUS-2890: A/B TEST: Pre-Select Paramount+ with SHO Plan -->
                        <!--Added just isActivePlan(line #42), updateSinglCtaCopy(line #43) and  @handleCardSelected(line #44) for a/b test work, 
                        please also remove both line from PickAPlanBox component-->
                        <!-- END TEST PLOCTOPLUS-2890: A/B TEST: Pre-Select Paramount+ with SHO Plan -->
                        <PickAPlanBox
                            v-for="(plan, idx) in plans"
                            :key="idx"
                            :idx="idx"
                            :plan="plan"
                            :isNoFreeTrialCampaign="isNoFreeTrialCampaign"
                            :hasSingleTrialPlan="hasSingleTrialPlan"
                            @planSelected="handlePlanSelected"
                            :isActivePlan="activePlan === idx"
                            @updateSinglCtaCopy="updateSinglCtaCopy"
                            @handleCardSelected="handleCardSelected"></PickAPlanBox>
                    </div>

                    <!-- START TEST PLOCTOPLUS-2036 Pick a Plan: Plan Tiles Above Fold -->
                    <AbTestNativeAppViewPlans
                        v-if="isNativeAppView"
                        :plans="plans"
                        @planSelected="handlePlanSelected" />
                    <AbTestIntlLikePlans
                        v-if="isIntlLikeView"
                        :plans="plans"
                        @planSelected="handlePlanSelected" />
                    <!-- END TEST PLOCTOPLUS-2036 Pick a Plan: Plan Tiles Above Fold -->
                </div>

                <div class="pick-a-plan-grid-item" v-if="plansWithForeverCoupon">
                    <div class="forever-coupon-disclaimer" v-html="foreverCouponDisclaimer"></div>
                </div>
            </div>
        </section>

        <!-- START TEST PLOCTOPLUS-2890: A/B TEST: Pre-Select Paramount+ with SHO Plan -->
        <div v-if="isShoPlanPreSelectedOneCTA" class="container-pre-selected-one-cta">
            <ButtonCta :buttonConfig="getButtonConfig"></ButtonCta>
        </div>
        <!-- END TEST PLOCTOPLUS-2890: A/B TEST: Pre-Select Paramount+ with SHO Plan -->

        <ShowtimeBundle
            v-if="!hideBundleToggle"
            :bundleShowtime="displayBundlePlans"
            @toggleBundle="onToggleBundle"></ShowtimeBundle>

        <PartnerBundleCTA v-if="displayPartnerBundleCTA"></PartnerBundleCTA>
    </div>
</template>

<script>
    import { mapActions, mapState, mapGetters } from 'vuex';
    import StepsHeader from 'aa/vue/components/StepsHeader';
    import FormCheckbox from 'atoms/CheckboxElm';
    import PickAPlanBox from 'aa/vue/components/PickAPlanBox';
    import Product from 'aa/vue/models/Product';
    import { PickAPlanConfig } from 'aa/vue/FormConfig';
    import { tracking } from 'services/Tracking';
    import FormToggleButton from 'aa/vue/components/FormToggleButton';
    import ShowtimeBundle from 'aa/vue/components/ShowtimeBundle';
    import Breakpoints from 'helpers/breakpoints';
    import Feature, {
        CAMPAIGN_SERVICE_ENABLED,
        SKIP_EXPLAINER_STEPS,
    } from 'aa/helpers/featureConstants';
    // START TEST PLOCTOPLUS-2036 Pick a Plan: Plan Tiles Above Fold
    import AbTestNativeAppViewPlans from 'aa/vue/components/AbTestNativeAppViewPlans';
    import AbTestIntlLikePlans from 'aa/vue/components/AbTestIntlLikePlans';
    // END TEST PLOCTOPLUS-2036 Pick a Plan: Plan Tiles Above Fold
    import PartnerBundleCTA from 'aa/vue/components/PartnerBundleCTA';
    import accesabilityMixins from 'aa/vue/plugins/account/accesabilityMixins';

    // START TEST PLOCTOPLUS-2890: A/B TEST: Pre-Select Paramount+ with SHO Plan
    import ButtonCta from 'atoms/ButtonCta';
    import {
        PLAN_WITH_SHO_PRE_SELECTED_ONE_CTA,
        PLAN_WITH_SHO_PRESELECTED,
    } from 'helpers/featureConstants';
    // END TEST PLOCTOPLUS-2890: A/B TEST: Pre-Select Paramount+ with SHO Plan

    export default {
        name: 'PickAPlan',
        mixins: [accesabilityMixins],
        data: function () {
            return {
                pricing: null,
                paymentForm: 'paymentForm',
                papConfig: PickAPlanConfig,
                buttonConfig: {},
                checkboxConfig: {},
                breakpoints: new Breakpoints(),
                enteredStepWithBundle: this.$store.getters.bundleShowtime,
                displayBundlePlans: this.$store.getters.bundleShowtime,

                // START TEST PLOCTOPLUS-2036 Pick a Plan: Plan Tiles Above Fold
                isNativeAppView: this.$store.getters['variant/isPlanTilesAboveFoldNativeAppView'],
                isIntlLikeView: this.$store.getters['variant/isPlanTilesAboveFoldIntlLikeView'],
                // END TEST PLOCTOPLUS-2036 Pick a Plan: Plan Tiles Above Fold

                addOnCode: Product.ADDON_PARTNER_BUNDLE,
                hasSingleTrialPlan: false,
                // START TEST PLOCTOPLUS-2890: A/B TEST: Pre-Select Paramount+ with SHO Plan
                copyTextSingleCTA: '',
                // END TEST PLOCTOPLUS-2890: A/B TEST: Pre-Select Paramount+ with SHO Plan
            };
        },
        computed: {
            ...mapGetters({
                featureFlagIsActive: 'featureFlags/isActive',
            }),

            getPickAPlanWrapperClassList() {
                return {
                    // START TEST PLOCTOPLUS-2890: A/B TEST: Pre-Select Paramount+ with SHO Plan
                    'is-pre-select-abtest': this.isPreSelectAbTest,
                    // END TEST PLOCTOPLUS-2890: A/B TEST: Pre-Select Paramount+ with SHO Plan
                };
            },

            getGridClass() {
                return {
                    'pick-a-plan-grid-item': true,
                };
            },

            hasSkipExplainers() {
                return this.featureIsActive(SKIP_EXPLAINER_STEPS);
            },
            dspHeading() {
                return this.hasSkipExplainers && this.getHeading;
            },
            getHeading() {
                return this.pageAttributes.aa_page_header;
            },
            recurly() {
                return this.$store.state.payment.recurly;
            },

            plans() {
                if (this.displayPartnerPlans) {
                    return this.getSortedPlans(this.plansForPartnerBundle);
                }
                if (Feature.enabled(CAMPAIGN_SERVICE_ENABLED)) {
                    const plans = this.$store.getters['plan/getPlans'].filter(
                        (plan) => !plan.isBundlePlan,
                    );
                    return this.getSortedPlans(plans);
                } else {
                    if (this.plansWithTierSuppression) {
                        return this.getSortedPlans(this.plansWithTierSuppression);
                    }

                    const plans = this.$store.getters['plan/getPlans'].filter(
                        (plan) => !plan.isBundlePlan && plan.trial !== this.isNoFreeTrialCampaign,
                    );
                    return this.getSortedPlans(plans);
                }
            },

            plansWithTierSuppression() {
                if (this.isCampaign) {
                    let suppressionTier = null;

                    if (this.pageAttributes.hide_essential_plan) {
                        suppressionTier = Product.PACKAGE_CODE.LCP;
                    } else if (this.pageAttributes.hide_premium_plan) {
                        suppressionTier = Product.PACKAGE_CODE.CF;
                    }

                    if (suppressionTier !== null) {
                        return this.$store.getters['plan/getPlans'].filter((plan) => {
                            return (
                                plan.package_code !== suppressionTier &&
                                plan.trial !== this.isNoFreeTrialCampaign &&
                                !plan.isBundlePlan
                            );
                        });
                    }
                }

                return null;
            },

            plansForPartnerBundle() {
                if (Feature.enabled(CAMPAIGN_SERVICE_ENABLED)) {
                    return this.$store.getters['plan/getPlans'].filter(
                        (plan) => !plan.isBundlePlan,
                    );
                } else {
                    return this.$store.getters['plan/getPlans'].filter(
                        (plan) =>
                            plan.hasAddOnCode(this.addOnCode) &&
                            plan.trial !== this.isNoFreeTrialCampaign,
                    );
                }
            },

            subHeadingClass() {
                const classList = ['plan-disclaimer'];

                if (!this.isGiftFlow) {
                    classList.push('qt-cancelcopy');
                }

                return classList;
            },

            subHeadingCopy() {
                const isExSub = this.$store.getters['user/isExSubscriber'];

                if (this.isNoFreeTrialCampaign) {
                    return this.pageAttributes.aa_pap_subheading_no_trial;
                }

                if (this.isGiftFlow) {
                    return this.giftSubHeading;
                }

                if (this.hasSkipExplainers) {
                    return isExSub || this.promo
                        ? this.pageAttributes.aa_page_sub_header_exsub
                        : this.pageAttributes.aa_page_sub_header;
                } else {
                    return isExSub
                        ? this.pageAttributes.aa_pap_sub_heading_ex_sub
                        : this.pageAttributes.aa_pap_sub_heading;
                }
            },

            giftSubHeading: function () {
                let price = window.sessionStorage.getItem('gift_amount'),
                    formattedPrice = price ? '$' + Number(price).toFixed(2) : '';

                return this.pageAttributes.aa_pap_gift_sub_heading_format.replace(
                    '%GIFT_AMOUNT%',
                    formattedPrice,
                );
            },

            annualToggleDefaultState() {
                //set default annual plan state for toggle
                return this.annualPlanFilterIsOn
                    ? this.getToggleRightLabel
                    : this.getToggleLeftLabel;
            },

            getToggleLeftLabel() {
                return Product.TYPE_MONTHLY.toUpperCase();
            },

            getToggleRightLabel() {
                return Product.TYPE_ANNUAL.toUpperCase();
            },

            displayAnnualCheckbox() {
                return !this.isGiftFlow && !this.hideAnnualToggle;
            },

            hideAnnualToggle() {
                return this.isCampaign && this.pageAttributes.hide_monthly_annual_toggle;
            },

            hideBundleToggle() {
                if (this.enteredStepWithBundle) {
                    return this.$store.getters['pageAttributes/hideBasePlanCTAs'];
                }

                return !this.displayBundlePlans;
            },

            displayPartnerBundleCTA() {
                return this.$store.getters['isPartnerBundle'];
            },

            displayPartnerPlans() {
                return this.$store.getters['isPartnerBundle'] && this.plansForPartnerBundle;
            },

            //We are checking here any of the plans has a valid coupon, if yes then update annual plan checkbox text
            isValidCouponAndCheckboxText() {
                let isValid = false;
                if (this.pageAttributes?.aa_pap_annual_plan_coupon_checkbox_text) {
                    for (let key in this.plans) {
                        if (this.plans[key]?.couponIsValid) {
                            isValid = this.plans[key].couponIsValid;
                            break;
                        }
                    }
                }
                return isValid;
            },

            annualCheckboxLabelCopy() {
                if (this.plans.length === 1) {
                    return this.annualCheckboxLabelCopySingleTier;
                }

                try {
                    if (this.isValidCouponAndCheckboxText) {
                        return this.pageAttributes.aa_pap_annual_plan_coupon_checkbox_text;
                    }

                    let format = this.pageAttributes.aa_pap_annual_plan_checkbox_format;

                    return format
                        .split(/(%[\w_]+%)/)
                        .map((part) => {
                            switch (part) {
                                case '%LCP_ANNUAL_PLAN_MONTHLY_PRICE_WITH_UNIT%':
                                    return this.lcpAnnualPlan?.annualPlanMonthlyPriceWithUnit();
                                case '%CF_ANNUAL_PLAN_MONTHLY_PRICE_WITH_UNIT%':
                                    return this.cfAnnualPlan?.annualPlanMonthlyPriceWithUnit();
                                default:
                                    return part;
                            }
                        })
                        .join('');
                } catch (e) {
                    return '';
                }
            },

            annualCheckboxLabelCopySingleTier() {
                try {
                    const format =
                        this.pageAttributes.aa_pap_annual_plan_checkbox_single_tier_format;

                    let annualPlan, planName;

                    // Get the single annual plan for the campaign
                    if (this.plansWithTierSuppression[0].isLCP) {
                        annualPlan = this.lcpAnnualPlan;
                        planName = this.pageAttributes.aa_pap_plan_heading_lcp ?? '';
                    } else {
                        annualPlan = this.cfAnnualPlan;
                        planName = this.pageAttributes.aa_pap_plan_heading_cf ?? '';
                    }

                    // Apply the single annual plan value props to the label
                    return format
                        .split(/(%[\w_]+%)/)
                        .map((part) => {
                            switch (part) {
                                case '%ANNUAL_PLAN_MONTHLY_PRICE_WITH_UNIT%':
                                    return annualPlan?.annualPlanMonthlyPriceWithUnit();
                                case '%ANNUAL_PLAN_NAME%':
                                    return planName;
                                default:
                                    return part;
                            }
                        })
                        .join('');
                } catch (e) {
                    return '';
                }
            },

            isLoggedIn: function () {
                return this.$store.state.user.isLoggedIn;
            },

            plansGridClass() {
                let classList;

                classList = `plan-list-grid${this.plans.length === 1 ? ' single-col' : ''}`;

                return classList;
            },

            /**
             * Returns true if one or more plans has a forever coupon.
             *
             * @returns {Boolean}
             */
            plansWithForeverCoupon() {
                let hasForeverCoupon = Boolean(
                    this.plans.filter((plan) => plan?.config?.recurlyCoupon?.duration === 'forever')
                        .length,
                );

                return hasForeverCoupon;
            },

            foreverCouponDisclaimer() {
                return this.pageAttributes.aa_forever_coupon_disclaimer;
            },

            ...mapGetters({
                annualPlanFilterIsOn: 'plan/annualPlanFilterIsOn',
                annualPlans: 'plan/annualPlans',
                bundleShowtime: 'bundleShowtime',
                cfAnnualPlan: 'plan/cfAnnualPlan',
                currentPlan: 'plan/getCurrentPlan',
                getNextStep: 'flow/getNextStep',
                isCampaign: 'flow/isCampaign',
                isEdu: 'flow/isEdu',
                isExSubscriber: 'user/isExSubscriber',
                isGiftFlow: 'flow/isGift',
                lcpAnnualPlan: 'plan/lcpAnnualPlan',
                featureIsActive: 'featureIsActive',
                isNoFreeTrialCampaign: 'flow/isNoFreeTrialCampaign',
                // START TEST PLOCTOPLUS-2890: A/B TEST: Pre-Select Paramount+ with SHO Plan
                isShoPlanPreSelectedOneCTA: 'featureFlags/isShoPlanPreSelectedOneCTA',
                isPreSelectAbTest: 'featureFlags/isPreSelectAbTest',
                // END TEST PLOCTOPLUS-2890: A/B TEST: Pre-Select Paramount+ with SHO Plan
            }),
            ...mapState('payment', {
                recurly: (state) => state.recurly,
            }),
            ...mapState('pageAttributes', {
                pageAttributes: (state) => state.attributes,
            }),
            ...mapState(['user']),
            ...mapState({
                promo: (state) => state.promo,
            }),

            // START TEST PLOCTOPLUS-2890: A/B TEST: Pre-Select Paramount+ with SHO Plan
            ...mapState('plan', {
                activePlan: (state) => state.activePlan,
                selectedPlanInfo: (state) => state.selectedPlanInfo,
            }),
            getButtonConfig() {
                return {
                    displayText: this.copyTextSingleCTA ? this.copyTextSingleCTA : '',
                    aaLink: 'pick a plan||continue|||||||',
                    ariaLabel: this.selectedPlanInfo?.planTitle ?? '',
                    href: 'javascript:void(0)',
                    styleClass: 'qt-continuebtn button',
                    tabIndex: 0,
                    event: () => {
                        try {
                            this.handlePlanSelected(
                                this.selectedPlanInfo.plan,
                                this.selectedPlanInfo.ctaText,
                            );
                        } catch (e) {}
                    },
                };
            },
            // END TEST PLOCTOPLUS-2890: A/B TEST: Pre-Select Paramount+ with SHO Plan
        },
        components: {
            PartnerBundleCTA,
            StepsHeader,
            FormCheckbox,
            PickAPlanBox,
            FormToggleButton,
            ShowtimeBundle,
            // START TEST PLOCTOPLUS-2036 Pick a Plan: Plan Tiles Above Fold
            AbTestNativeAppViewPlans,
            AbTestIntlLikePlans,
            // END TEST PLOCTOPLUS-2036 Pick a Plan: Plan Tiles Above Fold

            // START TEST PLOCTOPLUS-2890: A/B TEST: Pre-Select Paramount+ with SHO Plan
            ButtonCta,
            // END TEST PLOCTOPLUS-2890: A/B TEST: Pre-Select Paramount+ with SHO Plan
        },
        methods: {
            ...mapActions('plan', ['updateSelectedPlan', 'filterPlans']),
            handlePlanSelected: function (plan, ctaText) {
                this.updateSelectedPlan(plan);

                // track action on plan selected and showtime bundle plan selected
                this.trackSelectPlanBtn(plan, ctaText);

                this.handleSubmit();
            },
            handleCheckboxChange: function (e) {
                // update value to boolean based on active toggle switch
                if (typeof e.value !== 'boolean') {
                    e.value = e.value === 'ANNUAL';
                }

                let selectedPlanType = e.value ? Product.TYPE_ANNUAL : Product.TYPE_MONTHLY;

                // track toggle monthly or annual
                this.trackSelectPlanToggle(e, selectedPlanType);

                // update store with selected plan type
                this.filterPlans({
                    planType: selectedPlanType,
                });
            },

            handleSubmit: function () {
                // TODO: validation before moving on
                this.$router.push(this.getNextStep);
            },

            onToggleBundle() {
                this.displayBundlePlans = !this.displayBundlePlans;

                if (!this.displayBundlePlans) {
                    this.$store.dispatch('flow/skipNextBundleStep', true);
                }
            },

            updateAnnualCheckboxPlanBundlePricingInfo() {
                const displayAnnualToggle = this.displayAnnualCheckbox;

                // if annual plan filter is on, updatePricingInfo taken care of by PickAPlanBox child component
                if (displayAnnualToggle && this.displayBundlePlans && !this.annualPlanFilterIsOn) {
                    this.annualPlans.forEach((plan) => {
                        this.$store.dispatch('plan/updatePricingInfo', {
                            plan,
                        });
                    });
                }
            },

            // this handle when app is navigating between components in vue router.
            setShowtimeBundleFooterPadding() {
                try {
                    let showtimBundleFooterPaddingThreshold = this.breakpoints.getMin('desktop');
                    if (window.innerWidth <= showtimBundleFooterPaddingThreshold) {
                        this.addShowtimeBundleFooterPadding();
                    } else {
                        this.removeShowtimeBundleFooterPadding();
                    }
                } catch (e) {}
            },

            addShowtimeBundleFooterPadding() {
                let footer = document.getElementsByClassName('global-footer')[0];
                if (footer !== undefined) {
                    footer.classList.add('showtime-bundle-footer');
                }
            },

            removeShowtimeBundleFooterPadding() {
                let footer = document.getElementsByClassName('global-footer')[0];
                if (footer !== undefined) {
                    footer.classList.remove('showtime-bundle-footer');
                }
            },

            trackSelectPlanBtn(plan, ctaText) {
                let trackSelectAction = this.displayBundlePlans
                    ? 'trackSelectShowtimeBundle'
                    : 'trackPickPlanSelection';

                let trackingParams = {
                    pickPlanCTA: 'select_plan',
                    pickPlanSelection: '1',
                    ctaText: ctaText,
                };

                Object.assign(trackingParams, this.$store.getters['getProductParamsForTracking']);

                tracking.trackAction(trackSelectAction, trackingParams);
            },

            trackSelectPlanToggle(e, selectedPlanType) {
                //track if user has checked annual option
                if (e.origin !== 'onCreated') {
                    let trackToggleAction = this.displayBundlePlans
                        ? 'trackPickPlanShowtimeBundleToggle'
                        : 'trackPickPlanToggle';

                    const trackingToggleParams = {
                        pickPlanIsAnnual:
                            selectedPlanType === Product.TYPE_ANNUAL ? 'true' : 'false',
                    };

                    tracking.trackAction(trackToggleAction, trackingToggleParams);
                }
            },

            updateHasSingleTrialPlan(plans) {
                const trialPlans = plans.filter((plan) => plan.trial === true);
                this.hasSingleTrialPlan = trialPlans.length === 1;
            },

            // START TEST PLOCTOPLUS-2890: A/B TEST: Pre-Select Paramount+ with SHO Plan
            handleCardSelected({ idx, ctaText, plan }) {
                this.$store.commit('plan/setActivePlan', idx);
                if (this.isShoPlanPreSelectedOneCTA) {
                    this.$store.commit('plan/setSelectedPlanInfo', { ctaText, plan });
                }
            },
            updateSinglCtaCopy(text) {
                this.copyTextSingleCTA = text;
            },
            // END TEST PLOCTOPLUS-2890: A/B TEST: Pre-Select Paramount+ with SHO Plan
            getSortedPlans(plans) {
                if (!plans) {
                    return [];
                }
                // Making plans descending order by tier, as per requirements default will be descending
                // CMS user can override this by checking a checkbox(Plans Order Ascending) in the CMS
                if (!this.pageAttributes?.aa_pap_plans_order_ascending && plans.length > 1) {
                    return plans.sort((a, b) => Number(b.tier) - Number(a.tier));
                }
                return plans;
            },
        },

        created() {
            this.$store.dispatch('flow/skipNextBundleStep', false);

            if (this.recurly) {
                this.updateAnnualCheckboxPlanBundlePricingInfo();
            }

            this.updateHasSingleTrialPlan(this.plans);
            // START TEST PLOCTOPLUS-2890: A/B TEST: Pre-Select Paramount+ with SHO Plan
            if (this.$store.getters['variant/plansWithShoPreSelected']) {
                this.$store.commit('featureFlags/enableFeature', PLAN_WITH_SHO_PRESELECTED);
            }
            if (this.$store.getters['variant/plansWithShoPreSelectedOneCTA']) {
                this.$store.commit(
                    'featureFlags/enableFeature',
                    PLAN_WITH_SHO_PRE_SELECTED_ONE_CTA,
                );
            }
            // END TEST PLOCTOPLUS-2890: A/B TEST: Pre-Select Paramount+ with SHO Plan
        },

        mounted() {
            this.setShowtimeBundleFooterPadding();
        },

        destroyed() {
            this.removeShowtimeBundleFooterPadding();
        },

        watch: {
            recurly() {
                this.updateAnnualCheckboxPlanBundlePricingInfo();
            },
            displayBundlePlans() {
                this.updateAnnualCheckboxPlanBundlePricingInfo();
            },

            plans(newPlans) {
                this.updateHasSingleTrialPlan(newPlans);
            },
        },
    };
</script>
