<template>
    <div :class="getParentClass()">
        <div v-if="hasPlanCoupon()" class="text coupon">
            <div class="price-info">
                <span class="stripe">{{ getValuePropStateText() }}</span>

                <!-- These spans (price & duration) MUST be adjacent in code, do not stack -->
                <span class="price">{{ planPriceWithCoupon('price') }}</span>
                <span class="duration">{{ planPriceWithCoupon('duration') }}</span>
            </div>
            <div class="additional-info">{{ planPriceWithCoupon('info') }}</div>
        </div>
        <div v-else :class="getChildClass()">
            <span>{{ getValuePropStateText() }}</span>
            <span class="duration" v-if="isPriceState()">{{ getPlanDuration }}</span>
        </div>
        <div class="price-breakdown" v-if="isPriceValidForBreakDown">{{ monthlyBreakDown }}</div>
    </div>
</template>
<script>
    import { LAST } from 'aa/vue/constants/shared';
    import { mapGetters, mapState } from 'vuex';
    import Product from 'aa/vue/models/Product';
    import { getLocale } from 'services/LocalizationService';

    export default {
        name: 'ValuePropState',
        data: function () {
            return {
                defaultText: '_text',
                defaultCheckbox: '_checkbox',
            };
        },
        props: {
            plan: {
                type: Object,
                required: true,
            },
            valueProp: {
                type: Object,
                required: true,
            },
        },
        computed: {
            ...mapState('user', ['isExSubscriber']),
            ...mapGetters({
                upsellPlansAnnualEnabled: 'featureFlags/upsellPlansAnnualEnabled',
            }),
            isPriceValidForBreakDown() {
                return (
                    this.upsellPlansAnnualEnabled &&
                    this.isPriceState() &&
                    this.plan.planType === Product.TYPE_ANNUAL
                );
            },
            monthlyBreakDown() {
                return this.$getLocale('thats') + ' ' + this.plan.annualPlanMonthlyPriceWithUnit();
            },
            getValueProp() {
                if (this.isPriceState()) {
                    if (this.plan.isLCP) {
                        this.valueProp.essential_text = this.getPlanPrice;
                    } else {
                        this.valueProp.premium_text = this.getPlanPrice;
                    }
                }
                return this.valueProp;
            },
            getPlanPrice() {
                return this.plan.getRetailPrice();
            },
            hasTrialAndNoExSub() {
                return !this.isExSubscriber && this.plan.trial;
            },
            getPlanDuration() {
                let durationText = '/' + getLocale('month_abbr', {}, 'mo');
                if (this.plan?.planType === Product.TYPE_ANNUAL) {
                    durationText = '/' + getLocale('year_abbr', {}, 'mo');
                }

                if (this.upsellPlansAnnualEnabled && this.hasTrialAndNoExSub) {
                    return durationText + ' ' + this.$getLocale('after_trial');
                }
                return durationText;
            },
        },
        methods: {
            planPriceWithCoupon(action) {
                if (!this.hasPlanCoupon) {
                    return '';
                }

                this.plan.setMonthFormat('$%s for %s %s');
                const promoStr = this.plan.getPromoStringByMonth(this.isExSubscriber);

                if (promoStr) {
                    let [priceOnly, ...additionalInfo] = promoStr.split(' ');

                    if (action === 'price') {
                        return priceOnly;
                    } else if (action === 'duration') {
                        return this.getPlanDuration;
                    } else {
                        return additionalInfo.join(' ');
                    }
                }

                return '';
            },
            getParentClass() {
                return {
                    'upsell-plans-grid-item': true,
                    'plan-state': true,
                    'coupon-state': this.hasPlanCoupon(),
                    'price-state': this.isPriceState() && !this.hasPlanCoupon(),
                    hasAnnual: this.upsellPlansAnnualEnabled,
                };
            },
            getChildClass() {
                return {
                    checkmark: this.hasCheckMark(),
                    dash: this.hasDashIcon(),
                    text: this.textOnly(),
                    center: true,
                    'price-info': this.isPriceState(),
                };
            },
            textOnly: function () {
                return this.valueProp[this.getTextKey()] !== '';
            },
            hasCheckMark: function () {
                return (
                    this.getValueProp[this.getCheckBoxKey()] &&
                    !this.getValueProp[this.getTextKey()]
                );
            },
            hasDashIcon: function () {
                return (
                    !this.getValueProp[this.getCheckBoxKey()] &&
                    !this.getValueProp[this.getTextKey()]
                );
            },
            getCheckBoxKey: function () {
                return this.getPlanTypeValuePropPrefix() + this.defaultCheckbox;
            },
            getTextKey: function () {
                return this.getPlanTypeValuePropPrefix() + this.defaultText;
            },
            getPlanTypeValuePropPrefix() {
                return this.plan.isLCP ? Product.TIER_ESSENTIAL : Product.TIER_PREMIUM;
            },
            getValuePropStateText() {
                return this.getValueProp[this.getTextKey()];
            },
            hasPlanCoupon() {
                if (this.isPriceState()) {
                    return this.plan.isCouponValidAndAllowed();
                }
                return false;
            },
            isPriceState() {
                return this.valueProp?.position === LAST;
            },
        },
    };
</script>
