export class PageAttribute {
    constructor(data) {
        this.data = data;
    }

    get(key, defaultValue = '') {
        if (this.data === null) return defaultValue;

        if (!this.data.hasOwnProperty(key)) return defaultValue;

        return this.data[key];
    }
}
