import { FLOWTYPE_SHO_MIGRATED } from 'aa/vue/constants/shared';
import { FLOW_TYPE } from 'models/FlowModel';

export const USER_REG_TYPE = Object.freeze({
    CBS_SPORTS: 'cbssports_rendezvous',
    EDU: 'student',
    GIFT: 'gift',
    LIVE_TV: 'mvpd',
    RENDEZVOUS: 'rendezvous',
    STANDARD: 'standard',
    MVPD: 'mvpd',
});

export const userRegTypesForFlow = {
    [FLOW_TYPE.CBS_SPORTS]: USER_REG_TYPE.CBS_SPORTS,
    [FLOW_TYPE.EDU]: USER_REG_TYPE.EDU,
    [FLOW_TYPE.GIFT]: USER_REG_TYPE.GIFT,
    [FLOW_TYPE.LIVE_TV]: USER_REG_TYPE.LIVE_TV,
    [FLOWTYPE_SHO_MIGRATED]: 'showtime',
};

export const PAGE_TYPES = Object.freeze({
    ACCOUNT: 'account',
    ACTIVATION_PAGE: 'rendezvous_activation_enter_code',
    COMPLETE_PAGE: 'rendezvous_activation_complete',
    CONFIRMATION_NO_ACCOUNT: 'tv-provider_nolinkaccount',
    EDU: 'svod_edu',
    EXPLAINER_PAGE: 'rendezvous_explainer',
    LOGIN_OPTIONS: 'rendezvous_activation_signin',
    SIGNIN: 'svod_signin',
    SIGNIN_SHO_MIGRATED: 'sho_signin',
    SIGNUP: 'svod_signup',
    TV_PROVIDER_SELECTION_SCREEN: 'tv-provider_select',
    BUNDLE_INTERSTITIAL: 'upsell_partner_bundle',
    PICK_A_PLAN_DEFAULT: 'svod_pickaplan',
    PICK_A_PLAN_BUNDLE: 'svod_pick_bundle_plan',
    ACTIVATION_CONSENT: 'activation_consent',
    PPLUS_NO_ACCESS: 'account_subscription-error',
});

export const ACTION_NAMES = Object.freeze({
    BIND_SUCCESS: 'oAuthTrackPaymentComplete',
    TV_PROVIDER_TRACK_ACCOUNT_MANAGEMENT: 'trackManageSubscriptionButtonClick',
    MVPD_ACCOUNT_BINDING_SUCCESS: 'trackMVPDAccountBindingSuccess',
    MVPD_ACCOUNT_DISCONNECT_COMPLETE: 'trackManualMVPDDisconnectComplete',
});

export const SUBSCRIBER_FLAG = Object.freeze({
    EX_SUBSCRIBER: 'Ex Subscriber',
    NEW_SUBSCRIBER: 'New Subscriber',
});
