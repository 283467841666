/*
 * AAAbTestModuleLoader
 * Framework level class not to be modified for AbTest implementations
 * */

import { AbTestModuleLoader } from 'abTests/lib/AbTestModuleLoader';

/*
 * This constant promise is required to map classes accordingly for AA AB Test classes
 * DO NOT REMOVE
 * */
const AAImportPromise = (moduleName) => {
    return import(`../testClasses/${moduleName}`);
};

export class AAAbTestModuleLoader extends AbTestModuleLoader {
    constructor() {
        super({
            importPromise: AAImportPromise,
            isAA: true,
        });
    }
}
