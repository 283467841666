import { mapState, mapGetters } from 'vuex';
import { FLOW_TYPE } from 'models/FlowModel';
import { FeatureKeys } from 'aa/helpers/featureConstants';

const SUBSCRIPTION_COUPON_PREFIX = 'aarpdiscount-';
const AARP_COUPON = 'aarp';

export default {
    computed: {
        ...mapState(['promo']),
        ...mapState('flow', {
            flow: (state) => state.config,
        }),
        ...mapGetters({
            featureFlagIsActive: 'featureFlags/isActive',
        }),
        ...mapState('authSuite', {
            accessStatus: (state) => state.accessStatus,
        }),
    },
    methods: {
        hasValidAarpCoupon: function () {
            let couponPrefix = FLOW_TYPE.AARP;

            let isAarpFeatureFlagEnabled = this.featureFlagIsActive(FeatureKeys.AARP_ENABLED);
            let aarpCoupon = this.promo;

            if (isAarpFeatureFlagEnabled && aarpCoupon.startsWith(couponPrefix)) {
                return true;
            }

            return false;
        },

        isAarpFlow: function () {
            let flowType = FLOW_TYPE.AARP;
            
            return this.flow && this.flow.type === flowType;
        },

        getAarpUserId: function () {
            let accessMethodsInUse = this.accessStatus.accessMethodsInUse;

            if (accessMethodsInUse.length < 1) {
                return null;
            }

            let providerCode = 'aarp';

            let aarpAccessMethod = accessMethodsInUse.find((accessMethod) => {
                return accessMethod.providerCode === providerCode;
            });

            return aarpAccessMethod?.providerUserId;
        },

        /**
         * @param {string} coupon
         * @returns {boolean}
         *
         * @description This method is used to determine if the coupon is an
         * aarp coupon derived from a aarp
         */
        isAarpSubscriptionCoupon: function (coupon) {
            if (!coupon) {
                return false;
            }
            return coupon.startsWith(SUBSCRIPTION_COUPON_PREFIX);
        },

        aarpCoupon: function () {
            return AARP_COUPON;
        },
    },
};
