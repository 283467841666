// START TEST PLOCTOPLUS-2610: pap free trial cta ab test
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters({
            isPapFreeTrialCtaAbTest: 'variant/isPapFreeTrialCtaAbTest',
        }),
    },
};
// END TEST PLOCTOPLUS-2610: pap free trial cta ab test
