import Product from 'aa/vue/models/Product';
import { sprintf } from 'sprintf-js';
import CouponDiscount from 'aa/vue/models/CouponDiscount';
import Duration from 'aa/vue/models/Duration';

export default class Plan {
    constructor(obj = {}, pageAttrs) {
        //
        // custom properties
        //

        this.addOnCodes = [];
        this.removeBaseTrial = false;
        this.pageAttrs = pageAttrs || {};
        this.config = {};

        this.couponDiscount = null;
        this.couponIsValid = null;
        this.couponRemovesTrial = null;
        this.allowedCouponTypes = ['percent', 'dollars'];
        /**
         * This is the coupon string provided by xhr when a recurly coupon is used
         * @type {null}
         */
        this.couponString = null;
        this.couponType = null;
        this.currency = null; // currency code
        this.applies_for_months = null;

        this.monthFormat = '$%s/month for %s %s';

        //
        // PRODUCT.php properties
        //

        /**
         * monthly | annual
         * @type {string}
         */
        this.planType = '';

        /**
         * @type {string}
         */
        this.titleForTracking = '';

        /**
         *
         * @type {string}
         */
        this.price = '';
        this.priceUnit = '';

        /**
         * Prefer using package_code for LCP/LC/CF checks
         * Use tiers numbers for relative order comparison
         * @type {number}
         */
        this.tier = -1;

        /**
         * @type {string}
         */
        this.package_code = '';

        /**
         *
         * @type {boolean}
         */
        this.trial = true;

        /**
         * REQUIRED TO PURCHASE THE PRODUCT
         * @type {string}
         */
        this.code = '';

        /**
         *
         * @type {string}
         */
        this.recurlyCode = '';

        /**
         *
         * @type {boolean}
         */
        this.isActive = false;

        /**
         *
         * @type {null}
         */
        this.trialInterval = null;

        /**
         *
         * @type {null}
         */
        this.trialUnit = null;

        this.trialEndDate = null;
        this.trialString = null;
        this.offerCouponCode = null;

        /**
         *
         * @type {string}
         */
        this.planTitle = '';

        /**
         *
         * @type {string}
         */
        this.rawPrice = '';

        this.showtimeAddon = null;

        // override defaults
        if (typeof obj === 'object') {
            let arrKeys = Object.entries(obj);
            for (let idx in arrKeys) {
                let key = arrKeys[idx][0];
                if (this.hasOwnProperty(key)) {
                    /**
                     * pageAttributes passed into Plan object from Store
                     */
                    if (key === 'pageAttrs' && pageAttrs && typeof pageAttrs === 'object') {
                        continue;
                    } else {
                        this[key] = obj[key];
                    }
                }
            }
        }
    }

    get trialDuration() {
        return new Duration(this.trialInterval, this.trialUnit, true);
    }

    get isBundlePlan() {
        return this.addOnCodes?.length && this.addOnCodes?.length > 0;
    }

    get isCF() {
        return (
            this.package_code === Product.PACKAGE_CODE.CF ||
            this.package_code === Product.PACKAGE_CODE.PM
        );
    }

    get isLCP() {
        return this.package_code === Product.PACKAGE_CODE.LCP;
    }

    get isMonthly() {
        return this.planType === Product.TYPE_MONTHLY;
    }

    get isAnnual() {
        return this.planType === Product.TYPE_ANNUAL;
    }

    /**
     * @returns {boolean}
     */
    get bundleShowtime() {
        return this.addOnCodes.includes('SHO');
    }

    get isFreeTrial() {
        return this.trial;
    }

    setMonthFormat(format) {
        this.monthFormat = format;
        return this;
    }

    /**
     * @param {string} addOnCode
     * @returns {boolean} True if the plan has the matching given add-on code.
     */
    hasAddOnCode(addOnCode) {
        return this.addOnCodes.includes(addOnCode);
    }

    setRemoveBaseTrial(bool) {
        this.removeBaseTrial = bool;
        return this;
    }

    setCoupon(config) {
        this.couponIsValid = Boolean(config.couponIsValid);

        if (this.couponIsValid) {
            this.couponDiscount = new CouponDiscount(config);
        } else {
            this.couponDiscount = null;
        }

        this.config = config;
        this.couponRemovesTrial = Boolean(config.couponRemovesTrial);
        this.couponString = config.couponString || this.getCouponString();
        this.couponType = this.couponDiscount?.discountType || null;
        this.applies_for_months = config?.applies_for_months || this.getCouponLength();
    }

    getCouponString() {
        let format = '$%s off for %s %s';
        let duration = 0;

        if (this.config?.recurlyCoupon?.temporal_amount) {
            duration = this.config.recurlyCoupon.temporal_amount;
        }

        if (this.isAnnual && duration >= 12) {
            return this.getCouponStrByYear(format, duration);
        } else {
            return this.getCouponStrByMonth(format, duration);
        }
    }

    getCouponStrByYear(format, duration) {
        try {
            let year = duration === 12 ? 'year' : 'years';
            let totalDuration = Number.parseFloat(duration);
            totalDuration = totalDuration / 12;
            if (this.couponDiscount?.priceDiscount) {
                return sprintf(format, this.couponDiscount.priceDiscount, totalDuration, year);
            }
        } catch (e) {
            return null;
        }

        return null;
    }

    getCouponStrByMonth(format, duration) {
        let unit = '';

        if (this.config?.recurlyCoupon?.temporal_unit) {
            unit = this.config.recurlyCoupon.temporal_unit;
            if (duration > 1) {
                unit = unit + 's';
            }
        }

        if (duration > 0 && this.couponDiscount?.priceDiscount) {
            return sprintf(format, this.couponDiscount.priceDiscount, duration, unit);
        }
        return null;
    }

    getCouponLength() {
        if (this.config?.recurlyCoupon?.temporal_amount) {
            return this.config.recurlyCoupon.temporal_amount;
        }
        return null;
    }

    getTrialCopy(noCoupon = false, isExSubscriber = false) {
        if (!noCoupon && this.couponString?.length > 0) {
            if (this.couponTypeAllowed()) {
                if (isExSubscriber) {
                    return null;
                }
                return this.getTrialCopyWithPercentOffCoupon();
            } else {
                return this.getTrialCopyWithCoupon();
            }
        } else {
            return this.getTrialCopyWithoutCoupon();
        }
    }

    getTrialCopyWithPercentOffCoupon() {
        if (this.hasValidCoupon()) {
            return this.getTrialCopyWithoutCoupon();
        }
        let cs = this.couponString;
        if (this.removeBaseTrial) {
            return cs;
        } else {
            return this.getTrialCopyWithoutCoupon() + ' & ' + cs;
        }
    }

    isCouponValidAndAllowed() {
        return this.couponIsValid === true && this.couponTypeAllowed();
    }

    hasValidCoupon() {
        return this.couponIsValid === true && this.couponString !== null;
    }

    /**
     * Returns the adjusted promo rate string based on the plan type.
     * @returns {string} The promo rate string.
     */
    getAdjustedPromoRateString(isExSubscriber = false) {
        if (this.isAnnual) {
            return this.getPromoStringByYear(isExSubscriber);
        } else if (this.isMonthly) {
            return this.getPromoStringByMonth(isExSubscriber);
        }
    }

    /**
     * Returns a string representing the promotional rate for the plan,
     * formatted as "$X/month for Y months" or "$X/month after trial".
     *
     * @returns {string} The promotional rate string.
     */
    getPromoStringByMonth(isExSubscriber = false) {
        if (typeof this.applies_for_months !== 'number' && !this.hasForeverCoupon()) {
            return '';
        }

        const rate = this.getAdjustedPromotionalRate() ?? '';
        const durationPromo = this.applies_for_months;
        let month = 'month';
        let format = this.monthFormat;

        format = this.foreverCouponPromoFormat(format, isExSubscriber, month);

        if (durationPromo > 1) {
            month = 'months';
        }

        let promoStr;
        try {
            promoStr = sprintf(format, rate, durationPromo, month);
        } catch (e) {
            promoStr = '';
        }
        return promoStr;
    }

    /**
     * Formats the coupon promo format for a plan with a forever coupon.
     * @param {string} format - The original format string.
     * @param {boolean} isExSubscriber - Indicates if the user is an exsub
     * @param {string} unit - The unit of measurement for the price.
     * @returns {string} The formatted coupon promo format.
     */
    foreverCouponPromoFormat(format, isExSubscriber, unit) {
        if (this.hasForeverCoupon()) {
            format = `$%s/${unit} after trial`;
        }

        if (this.hasForeverCoupon() && isExSubscriber) {
            format = `$%s/${unit}`;
        }
        return format;
    }

    /**
     * Returns a promotional string for the plan based on the duration of the
     * promotion.
     *
     * @returns {string} The promotional string for the plan.
     */
    getPromoStringByYear(isExSubscriber = false) {
        const durationPromo = this.applies_for_months;
        let rate = this.getAdjustedPromotionalRate() ?? '';
        let format = '$%s for first year';

        format = this.foreverCouponPromoFormat(format, isExSubscriber, 'year');

        let promoStr;
        try {
            if (durationPromo >= 12) {
                let year = durationPromo === 12 ? 'year' : 'years';
                format = '$%s/year for %s %s';

                let totalDuration = Number.parseFloat(durationPromo);
                totalDuration = totalDuration / 12;

                promoStr = sprintf(format, rate, totalDuration, year);
            } else {
                promoStr = sprintf(format, rate);
            }
        } catch (e) {
            promoStr = '';
        }

        return promoStr;
    }

    getRawPrice() {
        return Number(this.rawPrice);
    }

    couponTypeAllowed() {
        if (this.couponDiscount?.discountType) {
            return this.allowedCouponTypes.indexOf(this.couponDiscount.discountType) !== -1;
        }
        return false;
    }

    getAdjustedPromotionalRate() {
        if (this.couponDiscount?.priceSubtotal && this.couponTypeAllowed()) {
            return this.couponDiscount.priceSubtotal;
        } else {
            return null;
        }
    }

    getRetailPrice() {
        return '$' + Number.parseFloat(this.getRawPrice()).toFixed(2);
    }

    planRetailPrice() {
        return this.getAdjustedPromoRateString() ? this.getRetailPrice() : '';
    }

    getTrialCopyWithCoupon() {
        return this.pageAttrs.aa_pap_coupon_prefix + ' ' + this.couponString;
    }

    getTrialCopyWithoutCoupon(format = '%s %s FREE', plural = true, ucFirst = true) {
        let copyFormat = format ? format : `%s %s ${getLocale('free')}`;
        // override default format from page attrs
        if (this.pageAttrs.aa_pap_trial_copy) {
            copyFormat = this.pageAttrs.aa_pap_trial_copy;
        }

        const durationCopy = this.trialDuration.toCopyParts(plural, ucFirst);
        let ret;

        try {
            ret = sprintf(copyFormat, durationCopy.amount, durationCopy.unit);
        } catch (e) {
            ret = '';
        }

        return ret;
    }

    /**
     * @returns {string|null}
     */
    annualPlanMonthlyPriceWithUnit() {
        if (!this.isAnnual) {
            return null;
        }

        if (!this.rawPrice) {
            return null;
        }

        let price = Number.parseFloat(this.rawPrice);

        if (this.couponTypeAllowed() && this.couponDiscount?.priceSubtotal) {
            price = Number.parseFloat(this.couponDiscount.priceSubtotal);
        }

        price = (price / 12).toFixed(2);

        return `$${price}/month`;
    }

    showtimeBundlePrice() {
        return this.price.replace(/[\d.,]+/, this.showtimeBundleRawPrice());
    }

    showtimeBundleRawPrice() {
        const addonPrice = this.showtimeAddon?.addonPrice;

        if (!addonPrice) {
            return '';
        }

        try {
            const price = (Number(this.rawPrice) + addonPrice).toFixed(2);

            return price;
        } catch (e) {}

        return '';
    }

    /**
     * @param plan
     * @returns {boolean}
     */
    basePackageMatches(plan) {
        return this.package_code === plan.package_code && this.trial === plan.trial;
    }

    /**
     * @param plan
     * @returns {boolean}
     */
    basePackageAndPlanTypeMatches(plan) {
        return this.basePackageMatches(plan) && this.planType === plan.planType;
    }

    /**
     * @param plan
     * @returns {boolean}
     */
    allPackagesMatch(plan) {
        return (
            this.basePackageMatches(plan) && this.addOnCodes.join('|') === plan.addOnCodes.join('|')
        );
    }

    hasForeverCoupon() {
        return this.config?.recurlyCoupon?.duration === 'forever';
    }
}
